import { tz } from 'moment-timezone';
import { RRule, RRuleSet } from '../models/rrule.model';

type RRuleDataType = 'RAW_STR' | 'STR' | 'INT' | 'INT_ARR' | 'RAW_STR_ARR';

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const noTimezoneDateFormat = 'YYYY-MM-DD[T]HH:mm:ss';

const rRuleDataOrder: [RRuleDataType, keyof RRule][] = [
  ['RAW_STR', 'freq'],
  ['INT', 'interval'],
  ['INT', 'count'],
  ['STR', 'until'],
  ['INT_ARR', 'bysecond'],
  ['INT_ARR', 'byminute'],
  ['INT_ARR', 'byhour'],
  ['RAW_STR_ARR', 'byday'],
  ['INT_ARR', 'bymonthday'],
  ['INT_ARR', 'byyearday'],
  ['INT_ARR', 'byweekno'],
  ['INT_ARR', 'bymonth'],
  ['INT_ARR', 'bysetpos'],
  ['RAW_STR', 'wkst'],
];

export function rRuleToRecord(rRule: RRule, escapeQuotes = true) {
  const rRuleMap = rRule as unknown as { [x: string]: string | number | null | undefined };

  const aQuote = escapeQuotes ? '\\"' : '"';

  const rRuleData = rRuleDataOrder.map(([type, key]) => {
    const data = rRuleMap[key];
    if (data === null || data === undefined) return '';

    switch (type) {
      case 'RAW_STR':
      case 'INT':
        return data;
      case 'STR':
        return `'${data}'`;
      case 'RAW_STR_ARR':
      case 'INT_ARR':
        return `${aQuote}{${data}}${aQuote}`;
    }
  });

  return rRuleData.join(',');
}

export function recordToRRule(record: string, escapedQuotes = true) {
  if (!record.startsWith('(') || !record.endsWith(')')) throw new Error('Invalid record definition: must be surrounded by parentheses');

  const parsed: string[] = [];

  let quote = false;
  let escapeNext = false;

  const accumulator: string[] = [];

  for (const c of record.substring(1, record.length - 1)) {
    const escaped = escapeNext;
    escapeNext = false;

    if (c === '"' && !escaped) {
      quote = !quote;
      continue;
    }

    if (c === ',' && !escaped && !quote) {
      parsed.push(accumulator.join(''));
      accumulator.splice(0, accumulator.length);
      continue;
    }

    accumulator.push(c);
  }

  parsed.push(accumulator.join(''));

  if (parsed.length !== rRuleDataOrder.length)
    throw new Error(`Invalid record length. Expected ${rRuleDataOrder.length}, got ${parsed.length}`);

  const rawStrDelChars = escapedQuotes ? 2 : 1;

  const rRuleMap: { [x: string]: string | number | string[] | number[] | null | undefined } = {};

  for (let i = 0; i < rRuleDataOrder.length; i++) {
    const [type, key] = rRuleDataOrder[i];
    const data = parsed[i];

    if (data === '') continue;

    rRuleMap[key] = (() => {
      switch (type) {
        case 'RAW_STR':
          return data;
        case 'INT':
          return +data;
        case 'STR':
          return data.substring(1, data.length - 1);
        case 'RAW_STR_ARR':
          return data.substring(rawStrDelChars, data.length - rawStrDelChars).split(',');
        case 'INT_ARR':
          return data
            .substring(2, data.length - 2)
            .split(',')
            .map((n) => +n);
      }
    })();
  }

  return rRuleMap as unknown as RRule;
}

export function parseRecurrenceRule(rRule: string) {
  if (rRule.startsWith('(') && rRule.endsWith(')')) {
    return recordToRRule(rRule, false);
  }

  const rawRRuleData = rRule
    .split(';')
    .filter((r) => r)
    .map((r) => r.split('='))
    .map(([k, v]) => ({ [k]: v }))
    .reduce((a, r) => ({ ...a, ...r }), {});

  const rRuleData = rRuleDataOrder
    .map(([type, key]) => {
      let data: string | number | string[] | number[] = rawRRuleData[key.toUpperCase()];

      if (data) {
        switch (type) {
          case 'RAW_STR':
          case 'STR':
            break;
          case 'INT':
            data = +data;
            break;
          case 'RAW_STR_ARR':
            data = data.split(',');
            break;
          case 'INT_ARR':
            data = data.split(',').map((i) => +i);
            break;
        }
      }

      return {
        [key]: data,
      };
    })
    .reduce((a, r) => ({ ...a, ...r }), {});

  return rRuleData as unknown as RRule;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRRuleSet(record: Record<string, any>): RRuleSet {
  let rRuleSet: RRuleSet | undefined;

  if (record['rruleset']) rRuleSet = record['rruleset'];

  const dtstart = (record['StartTime'] as Date).toISOString();
  const dtend = (record['EndTime'] as Date).toISOString();

  if (!rRuleSet) {
    rRuleSet = {
      dtstart,
      dtend,
      rrule: null,
      exrule: null,
      rdate: null,
      exdate: null,
    };
  }

  rRuleSet.dtstart = dtstart;
  rRuleSet.dtend = dtend;

  rRuleSet.rrule = record['Recurrence'] ? parseRecurrenceRule(record['Recurrence']) : null;
  rRuleSet.exrule = record['RecurrenceException'] ? parseRecurrenceRule(record['RecurrenceException']) : null;

  return rRuleSet;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toPgRRuleSet(record: Record<string, any>): string {
  const rruleset = getRRuleSet(record);

  const startTime = `"${tz(rruleset.dtstart, localTimezone).format(noTimezoneDateFormat)}"`;
  const endTime = `"${tz(rruleset.dtend, localTimezone).format(noTimezoneDateFormat)}"`;

  const rrule = rruleset.rrule ? `"(${rRuleToRecord(rruleset.rrule)})"` : '';
  const exrule = rruleset.exrule ? `"(${rRuleToRecord(rruleset.exrule)})"` : '';

  const rdate = rruleset.rdate ? `"{${rruleset.rdate.map((d) => `\\"${d}\\"`).join(',')}}"` : '';
  const exdate = rruleset.exdate ? `"{${rruleset.exdate.map((d) => `\\"${d}\\"`).join(',')}}"` : '';

  return `(${startTime},${endTime},${rrule},${exrule},${rdate},${exdate})`;
}

export function toRRuleDate(date: string, timezone: string) {
  return tz(tz(date, timezone), 'Etc/UTC').format('YYYYMMDD[T]HHmmss[Z]');
}

export function fixPgRRule(rrule: RRule, timezone: string) {
  if (!rrule.until) return rrule;

  return {
    ...rrule,
    until: toRRuleDate(rrule.until, timezone),
  };
}

export function fixPgRRuleSetText(text: string | null, timezone: string) {
  if (!text) return text;
  return text
    .split(';')
    .map((s) => (s.startsWith('UNTIL=') ? `UNTIL=${toRRuleDate(s.substring(6).replace(' ', 'T'), timezone)}` : s))
    .join(';');
}

export function assembleRRuleString(rRule: RRule): string {
  return (
    rRuleDataOrder
      .map(([, key]) => (rRule[key] ? `${key.toUpperCase()}=${rRule[key]}` : null))
      .filter((r) => !!r)
      .join(';') + ';'
  );
}

export function assembleRRuleSetString(rRuleSet: RRuleSet, rRuleText: string, exRuleText: string | null, timezone: string): string {
  const entries: string[] = [];

  if (rRuleSet.dtstart) entries.push(`DTSTART:${toRRuleDate(rRuleSet.dtstart, timezone)}`);

  //! Unsupported by rrule library, see https://github.com/jakubroztocil/rrule/issues/322
  //! Not needed by the current usage of the library so this is fine
  //if (rRuleSet.dtend) entries.push(`DTEND:${this.toRRuleDate(rRuleSet.dtend, timezone)}`);

  entries.push(`RRULE:${rRuleText}`);
  if (exRuleText) entries.push(`EXRULE:${exRuleText}`);

  if (rRuleSet.rdate) entries.push(`RDATE:${rRuleSet.rdate.map((d) => toRRuleDate(d, timezone)).join(',')}`);
  if (rRuleSet.exdate) entries.push(`EXDATE:${rRuleSet.exdate.map((d) => toRRuleDate(d, timezone)).join(',')}`);

  return entries.join('\n');
}
