import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Signal,
  ViewChildren,
  computed,
  effect,
  signal,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { isNonNullable } from '@rcg/utils';

const allowedKeys = [...Array.from({ length: 10 }, (_, i) => String.fromCharCode(48 + i)), 'Backspace'];

@Component({
  selector: 'rcg-otp-pin-input',
  templateUrl: './otp-pin-input.component.html',
  styleUrls: ['./otp-pin-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule],
})
export class OtpPinInputComponent implements AfterViewInit, OnDestroy {
  @Input() numDigits: Signal<number> = signal(6);
  @Input() disabled: Signal<boolean> = signal(false);

  @Output() codeEntered = new EventEmitter<string>();

  readonly digits = computed(() => Array.from({ length: this.numDigits() }, (_, i) => i + 1));

  @ViewChildren('digitField', { read: ElementRef })
  readonly digitFields!: ElementRef<HTMLElement>[];

  private _viewInit = false;
  private _destroyed = false;

  constructor() {
    effect(() => {
      const disabled = this.disabled();
      if (disabled || !this._viewInit) return;

      this._tryFocus();
    });
  }

  async ngAfterViewInit() {
    await this._tryFocus();
    this._viewInit = true;
  }

  ngOnDestroy(): void {
    this._destroyed = true;
  }

  private async _tryFocus() {
    let focused = false;
    let tries = 0;
    let error;

    while (!this._destroyed && !focused && tries < 25) {
      //? Retry delay + initial delay to override other focus
      await new Promise((res) => setTimeout(res, 200));

      try {
        this.findDigitField(1)!.querySelector('input')!.focus();
        focused = true;
      } catch (e) {
        error = e;
      }

      tries++;
    }

    if (!focused && error) console.warn(`[${OtpPinInputComponent}]`, 'Digit focus failed:', error);
  }

  private findDigitField(digit: number) {
    return this.digitFields.find((d) => d.nativeElement.getAttribute('digit') === String(digit))?.nativeElement;
  }

  keyDown(event: KeyboardEvent, digit: number) {
    const field = this.findDigitField(digit);
    const input = field?.querySelector('input');

    //? Ctrl+v
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      setTimeout(() => {
        if (input) input.value = '';
      });

      return;
    }

    event.preventDefault();

    if (this.disabled()) return;
    if (!allowedKeys.includes(event.key)) return;

    if (!input) {
      console.error('MFA pin entry input not found!', { event, input });
      return;
    }

    if (event.key === 'Backspace') {
      if (!input.value.length) {
        const prevField = this.findDigitField(digit - 1);
        const prevInput = prevField?.querySelector('input');
        if (!prevInput) return;

        prevInput.value = '';
        prevInput.focus();
        return;
      }

      input.value = '';

      return;
    }

    const nextField = this.findDigitField(digit + 1);
    const nextInput = nextField?.querySelector('input');

    if (!nextInput) {
      if (input.value.length) return;
      input.value = event.key;
    } else {
      input.value = event.key;
    }

    nextInput?.focus();

    const code = this.digits()
      .map((d) => this.findDigitField(d)?.querySelector('input')?.value)
      .join('');
    if (code.length !== this.numDigits()) return;

    this.codeEntered.emit(code);
  }

  paste(event: ClipboardEvent) {
    event.preventDefault();

    if (this.disabled()) return;

    const data = event.clipboardData?.getData('text/plain');
    if (!data || !RegExp(`^[0-9]{${this.numDigits()}}$`).test(data)) return;

    const inputs = this.digits()
      .map((d) => this.findDigitField(d)?.querySelector('input'))
      .filter(isNonNullable);

    if (inputs.length !== this.numDigits()) {
      console.error('MFA pin entry inputs not found!', { inputs });
      return;
    }

    for (let i = 0; i < this.numDigits(); i++) {
      inputs[i].value = data[i];
    }

    this.codeEntered.emit(data);
  }

  public async clear() {
    for (const d of this.digits()) {
      const field = this.findDigitField(d);
      const input = field?.querySelector('input');

      if (!input) {
        console.error('MFA pin entry input not found!', { field, input });
        continue;
      }

      input.value = '';
    }

    await new Promise((res) => setTimeout(res));

    const firstField = this.findDigitField(1);
    const firstInput = firstField?.querySelector('input');

    if (!firstInput) {
      console.error('MFA pin entry input not found!', { firstField, firstInput });
      return;
    }

    firstInput.focus();
  }
}
