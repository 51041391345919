import { AfterViewInit, Component } from '@angular/core';

export type RcgBlankWindow = Window & { rcgBlankInit?: () => void };

@Component({
  selector: 'rcg-blank',
  template: '',
  standalone: true,
})
export class BlankComponent implements AfterViewInit {
  ngAfterViewInit() {
    const blankWindow = window as RcgBlankWindow;
    blankWindow.rcgBlankInit?.call(globalThis);
  }
}
